import React, { useState, lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import Loading from "components/shared-components/Loading";
import UtmFriendlyRedirect from "components/shared-components/UtmRedirect";
import axios from "axios";
import { USER_URL } from "constants/ApiConstant";
import { message } from "antd";

export const AppViews = ({ match }) => {
    const token = JSON.parse(localStorage.getItem("token"));
    const aclGroup = token ? token.aclGroup : "user";
    const [user, setUser] = useState({});
    const defaultPath =
        aclGroup === "user"
            ? "courses"
            : aclGroup === "organisation"
            ? "courses"
            : aclGroup === "tele-caller"
            ? "manage/users"
            : "manage/dashboard";

    const location = useLocation();
    useEffect(() => {
        ReactGA.pageview(location.pathname);
    }, [location]);

    useEffect(() => {
        const fetchEnrollStatus = async () => {
            try {
                const userRes = await axios.get(`${USER_URL}/me?enrolled=true`);
                if (userRes.data.status === "success") {
                    setUser(userRes.data);
                }
            } catch (err) {
                message.error({ content: err.message, duration: 3 });
            }
        };
        fetchEnrollStatus();
    }, []);

    return (
        <Suspense fallback={<Loading cover="content" />}>
            <Switch>
                <Route
                    exact
                    path={`${match.url}/manage/dashboard`}
                    component={lazy(() => import(`./admin/dashboard`))}
                />
                <Route
                    exact
                    path={`${match.url}/manage/users`}
                    component={lazy(() => import(`./admin/users`))}
                />
                <Route
                    exact
                    path={`${match.url}/manage/user-enrollments`}
                    component={lazy(() => import(`./admin/userEnrollments`))}
                />
                <Route
                    exact
                    path={`${match.url}/manage/organisations`}
                    component={lazy(() => import(`./admin/organisations`))}
                />
                <Route
                    exact
                    path={`${match.url}/manage/admins`}
                    component={lazy(() => import(`./admin/admins`))}
                />
                <Route
                    exact
                    path={`${match.url}/manage/telecaller-reports`}
                    component={lazy(() => import(`./admin/telecaller`))}
                />
                <Route
                    exact
                    path={`${match.url}/manage/campaign-reports`}
                    component={lazy(() => import(`./admin/campaigns`))}
                />
                <Route
                    exact
                    path={`${match.url}/manage/courses`}
                    component={lazy(() => import(`./admin/courses`))}
                />
                <Route
                    exact
                    path={`${match.url}/manage/batches`}
                    component={lazy(() => import(`./admin/batches`))}
                />
                <Route
                    exact
                    path={`${match.url}/manage/entrances`}
                    component={lazy(() => import(`./admin/assessments/entrance`))}
                />
                <Route
                    exact
                    path={`${match.url}/manage/studentrequests`}
                    component={lazy(() => import(`./admin/studentrequests`))}
                />
                <Route
                    exact
                    path={`${match.url}/manage/enrollments`}
                    component={lazy(() => import(`./admin/enrollments`))}
                />
                <Route
                    exact
                    path={`${match.url}/manage/enrollments/admin/special`}
                    component={lazy(() => import(`./admin/enrollments/specialEnrollment`))}
                />
                <Route
                    exact
                    path={`${match.url}/manage/coupons`}
                    component={lazy(() => import(`./admin/coupons`))}
                />
                <Route
                    exact
                    path={`${match.url}/manage/forum/categories`}
                    component={lazy(() => import(`./admin/forum/categories`))}
                />
                <Route
                    exact
                    path={`${match.url}/manage/forum`}
                    component={lazy(() => import(`./admin/forum`))}
                />
                <Route
                    exact
                    path={`${match.url}/manage/chapter/modular`}
                    component={({ match }) => {
                        const Chapter = lazy(() => import(`./admin/modularChapters`));
                        return <Chapter />;
                    }}
                />
                <Route
                    exact
                    path={`${match.url}/manage/chapter/:courseId`}
                    component={({ match }) => {
                        const Chapter = lazy(() => import(`./admin/chapters`));
                        return <Chapter courseId={match.params.courseId} />;
                    }}
                />
                <Route
                    path={`${match.url}/manage/subchapter/:chapterId`}
                    component={({ match }) => {
                        const SubChapter = lazy(() => import(`./admin/subchapters`));
                        return <SubChapter chapterId={match.params.chapterId} />;
                    }}
                />
                <Route
                    path={`${match.url}/manage/lesson/create/:subchapterId`}
                    component={({ match }) => {
                        const Component = lazy(() => import(`./admin/lessons/create`));
                        return <Component subchapterId={match.params.subchapterId} />;
                    }}
                />
                <Route
                    path={`${match.url}/manage/lesson/edit/:lessonId`}
                    component={({ match }) => {
                        const Component = lazy(() => import(`./admin/lessons/create`));
                        return <Component lessonId={match.params.lessonId} />;
                    }}
                />
                <Route
                    path={`${match.url}/manage/lesson/view/:lessonId`}
                    component={({ match }) => {
                        const Component = lazy(() => import(`./admin/lessons/view`));
                        return <Component lessonId={match.params.lessonId} />;
                    }}
                />
                <Route
                    path={`${match.url}/manage/assessment/create/:subchapterId`}
                    component={({ match }) => {
                        const Component = lazy(() => import(`./admin/assessments/create`));
                        if (match.params.subchapterId === "entrance") {
                            return <Component key={match.params.subchapterId} isEntrance={true} />;
                        }
                        return (
                            <Component
                                key={match.params.subchapterId}
                                subchapterId={match.params.subchapterId}
                            />
                        );
                    }}
                />
                <Route
                    path={`${match.url}/manage/assessment/edit/:lessonId`}
                    component={({ match }) => {
                        const Component = lazy(() => import(`./admin/assessments/create`));
                        return (
                            <Component
                                key={match.params.lessonId}
                                lessonId={match.params.lessonId}
                            />
                        );
                    }}
                />
                <Route
                    path={`${match.url}/manage/lesson/:subchapterId`}
                    component={({ match }) => {
                        const Lesson = lazy(() => import(`./admin/lessons`));
                        return <Lesson subchapterId={match.params.subchapterId} />;
                    }}
                />
                <Route
                    path={`${match.url}/content/ai/categories`}
                    component={() => {
                        const Categories = lazy(() => import(`./admin/categories`));
                        return <Categories type="AI" />;
                    }}
                />
                <Route
                    path={`${match.url}/content/quantum/categories`}
                    component={() => {
                        const Categories = lazy(() => import(`./admin/categories`));
                        return <Categories type="Quantum" />;
                    }}
                />
                <Route
                    path={`${match.url}/content/ai/tutorials`}
                    component={({ history }) => {
                        const Contents = lazy(() => import(`./admin/contents`));
                        return <Contents type="AI" history={history} />;
                    }}
                />
                <Route
                    path={`${match.url}/content/quantum/tutorials`}
                    component={({ history }) => {
                        const Contents = lazy(() => import(`./admin/contents`));
                        return <Contents type="Quantum" history={history} />;
                    }}
                />
                <Route
                    path={`${match.url}/content/ai/create`}
                    component={({ match }) => {
                        const Contents = lazy(() => import(`./admin/contents/create`));
                        return <Contents type="AI" contentId={null} />;
                    }}
                />
                <Route
                    path={`${match.url}/content/quantum/create`}
                    component={({ match }) => {
                        const Contents = lazy(() => import(`./admin/contents/create`));
                        return <Contents type="Quantum" contentId={null} />;
                    }}
                />
                <Route
                    path={`${match.url}/content/ai/edit/:contentId`}
                    component={({ match }) => {
                        const Contents = lazy(() => import(`./admin/contents/create`));
                        return <Contents type="AI" contentId={match.params.contentId} />;
                    }}
                />
                <Route
                    path={`${match.url}/content/quantum/edit/:contentId`}
                    component={({ match }) => {
                        const Contents = lazy(() => import(`./admin/contents/create`));
                        return <Contents type="Quantum" contentId={match.params.contentId} />;
                    }}
                />
                <Route
                    exact
                    path={`${match.url}/personal`}
                    component={lazy(() => import(`./user/subscription`))}
                />
                <Route
                    path={`${match.url}/account`}
                    component={lazy(() => import(`./user/setting`))}
                />
                <Route
                    exact
                    path={`${match.url}/organisation`}
                    component={lazy(() => import(`./organisations/subscriptions`))}
                />
                <Route
                    exact
                    path={`${match.url}/organisation/team`}
                    component={lazy(() => import(`./organisations/teams`))}
                />
                <Route
                    exact
                    path={`${match.url}/organisation/team/add`}
                    component={lazy(() => import(`./organisations/subscriptions/create.js`))}
                />
                <Route
                    exact
                    path={`${match.url}/billing`}
                    component={lazy(() => import(`./user/invoices`))}
                />
                <Route
                    exact
                    path={`${match.url}/billing/:invoiceId`}
                    component={({ match }) => {
                        const Invoice = lazy(() => import(`./user/invoices/invoice`));
                        return <Invoice type="invoice" invoiceId={match.params.invoiceId} />;
                    }}
                />
                <Route
                    exact
                    path={`${match.url}/manage/enrollments/:enrollmentId`}
                    component={({ match }) => {
                        const Invoice = lazy(() => import(`./user/invoices/invoice`));
                        return <Invoice type="enrollment" invoiceId={match.params.enrollmentId} />;
                    }}
                />
                <Route
                    path={`${match.url}/courses`}
                    component={lazy(() => import(`./user/enrollments/enrol.js`))}
                />
                <Route
                    path={`${match.url}/enrolled`}
                    component={lazy(() => import(`./user/enrollments/enrol.js`))}
                />
                <Route
                    path={`${match.url}/entrance/:lessonId`}
                    component={({ match }) => {
                        const Component = lazy(() => import(`./user/enrollments/entrance.js`));
                        return <Component lessonId={match.params.lessonId} />;
                    }}
                />
                <Route
                    path={`${match.url}/course/:courseId`}
                    exact
                    component={({ match }) => {
                        const Enrollment = lazy(() => import(`./user/enrollments/enrol.js`));
                        return <Enrollment courseId={match.params.courseId} />;
                    }}
                />
                <Route
                    path={`${match.url}/course/access/:courseId`}
                    exact
                    component={({ match }) => {
                        const Course = lazy(() => import(`./user/course/Course.js`));
                        return <Course courseId={match.params.courseId} />;
                    }}
                />
                <Route
                    path={`${match.url}/lesson/:courseId/:lessonId`}
                    exact
                    component={({ match }) => {
                        const Lesson = lazy(() => import(`./user/course/Lesson.js`));
                        return (
                            <Lesson
                                courseId={match.params.courseId}
                                lessonId={match.params.lessonId}
                            />
                        );
                    }}
                />
                <Route
                    path={`${match.url}/webinar`}
                    exact
                    component={lazy(() => import("./user/webinar/"))}
                />
                <Route
                    exact
                    path={`${match.url}/sales`}
                    component={lazy(() => import(`./admin/sales`))}
                />
                <Route
                    exact
                    path={`${match.url}/manage/certification/exam`}
                    component={lazy(() => import(`./admin/certification/`))}
                />
                <Route
                    exact
                    path={`${match.url}/manage/certification/exam/:examId`}
                    component={lazy(() => import(`./admin/certification/responses`))}
                />
                {
                    aclGroup === "user" && user?.enrolled?.length > 0 && (<UtmFriendlyRedirect from={`${match.url}`} to={`${match.url}/enrolled`} />)
                }
                {
                    ((aclGroup === "user" && user?.enrolled?.length <= 0) || (aclGroup === "organisation")) && (<UtmFriendlyRedirect from={`${match.url}`} to={`${match.url}/courses`} />)
                }
                {
                    aclGroup === "admin" && <UtmFriendlyRedirect from={`${match.url}`} to={`${match.url}/manage/dashboard`} />
                }
                {
                    aclGroup === "tele-caller" && <UtmFriendlyRedirect from={`${match.url}`} to={`${match.url}/manage/users`} />
                }
            </Switch>
        </Suspense>
    );
};

export default AppViews;
