import React, { Component } from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { Drawer, Menu, message } from 'antd';
import { connect } from "react-redux";
import axios from 'axios';
import { USER_URL } from 'constants/ApiConstant';

export class NavPanel extends Component {
	state = { visible: false, user: { name: '' } };

	componentDidMount() {
    this.onReload();
  }

  onReload = async () => {
		try {
			const res = await axios.get(`${USER_URL}/me`);
			if (res.data.status === 'success') {
        this.setState({ user: res.data.user });
			} else {
				message.error({ content: res.data.error, duration: 3 });
			}
		} catch (err) {
      message.success({ content: err.message, duration: 3 });
		}
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
	};
  
  onLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "/auth";
	};
	
	render() {
		return (
      <>
        <Menu mode="veritical">
          <Menu.Item onClick={this.onLogout} style={{ color: '#fff'}}>
            Logged in as {this.state.user.name}&emsp;<LogoutOutlined />
          </Menu.Item>
        </Menu>
        <Drawer
          title="Theme Config"
          placement="right"
          width={350}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          NavPanel Contents here!
        </Drawer>
      </>
    );
	}
}

const mapStateToProps = ({ theme }) => {
  const { locale } =  theme;
  return { locale }
};

export default connect(mapStateToProps)(NavPanel);