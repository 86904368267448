import {
    DashboardOutlined,
    UserOutlined,
    GlobalOutlined,
    PartitionOutlined,
    EditOutlined,
    CustomerServiceOutlined,
    PieChartOutlined,
    NodeIndexOutlined,
    DesktopOutlined,
    SnippetsOutlined,
    CalendarOutlined,
    DollarCircleOutlined,
    PercentageOutlined,
    CommentOutlined,
    SisternodeOutlined,
    InfoCircleOutlined
} from "@ant-design/icons";
import { FiList, FiEdit,FiUserPlus} from "react-icons/fi";

const dashBoardNavTree = [
    {
        key: "certification-programme",
        path: "/app/courses",
        title: "Certification Programme",
        breadcrumb: true,
        aclGroup: ["user", "organisation"],
        submenu: []
    },
    {
        key: "my-subscription",
        path: "/app/personal",
        title: "App Subscription",
        breadcrumb: true,
        aclGroup: ["user"],
        submenu: []
    },
    {
        key: "org-subscription",
        path: "/app/organisation",
        title: "Team Subscription",
        aclGroup: ["organisation"],
        breadcrumb: true,
        submenu: []
    },
    {
        key: "org-team",
        path: "/app/organisation/team",
        title: "My Team",
        aclGroup: ["organisation"],
        breadcrumb: true,
        submenu: []
    },
    {
        key: "billing-settings",
        path: "/app/billing",
        title: "Billing & Payments",
        breadcrumb: true,
        aclGroup: ["user", "organisation"],
        submenu: []
    },
    {
        key: "account-settings",
        path: "/app/account",
        title: "Account Settings",
        breadcrumb: true,
        aclGroup: ["user", "organisation"],
        submenu: []
    },
    {
        key: "user",
        path: "/app/manage",
        title: "Admin Management",
        breadcrumb: true,
        aclGroup: ["admin", "tele-caller"],
        submenu: [
            {
                key: "manage-dashboard",
                path: "/app/manage/dashboard",
                title: "Dashboard",
                aclGroup: ["admin"],
                icon: DashboardOutlined,
                breadcrumb: true,
                submenu: []
            },
            {
                key: "manage-users",
                path: "/app/manage/users",
                title: "Individual",
                icon: UserOutlined,
                breadcrumb: true,
                submenu: []
            },
            {
                key: "manage-user-enrollments",
                path: "/app/manage/user-enrollments",
                title: "User Enrollments",
                icon: UserOutlined,
                breadcrumb: true,
                submenu: []
            },
            {
                key: "manage-organisations",
                path: "/app/manage/organisations",
                title: "Organisation",
                icon: GlobalOutlined,
                breadcrumb: true,
                submenu: []
            },
            {
                key: "manage-admins",
                path: "/app/manage/admins",
                title: "Admin",
                aclGroup: ["admin"],
                icon: UserOutlined,
                breadcrumb: true,
                submenu: []
            },
            {
                key: "manage-telecaller-reports",
                path: "/app/manage/telecaller-reports",
                title: "Telecaller Report",
                aclGroup: ["admin", "tele-caller"],
                icon: PieChartOutlined,
                breadcrumb: true,
                submenu: []
            },
            {
                key: "manage-campaign-reports",
                path: "/app/manage/campaign-reports",
                title: "Campaign Report",
                aclGroup: ["admin", "tele-caller"],
                icon: PieChartOutlined,
                breadcrumb: true,
                submenu: []
            }
        ]
    },
    {
        key: "content",
        path: "/app/content",
        title: "Content Management",
        icon: "",
        breadcrumb: true,
        aclGroup: "admin",
        submenu: [
            {
                key: "manage-ai",
                path: "/app/content/ai",
                title: "Automate AI/ML",
                icon: NodeIndexOutlined,
                breadcrumb: true,
                submenu: [
                    {
                        key: "manage-ai-category",
                        path: "/app/content/ai/categories",
                        title: "Categories",
                        icon: PartitionOutlined,
                        breadcrumb: true,
                        submenu: []
                    },
                    {
                        key: "manage-ai-content",
                        path: "/app/content/ai/tutorials",
                        title: "Tutorials",
                        icon: EditOutlined,
                        breadcrumb: true,
                        submenu: []
                    }
                ]
            },
            {
                key: "manage-quantum",
                path: "/app/content/quantum",
                title: "Quantum Computing",
                icon: DesktopOutlined,
                breadcrumb: true,
                submenu: [
                    {
                        key: "manage-quantum-category",
                        path: "/app/content/quantum/categories",
                        title: "Categories",
                        icon: PartitionOutlined,
                        breadcrumb: true,
                        submenu: []
                    },
                    {
                        key: "manage-quantum-content",
                        path: "/app/content/quantum/tutorials",
                        title: "Tutorials",
                        icon: EditOutlined,
                        breadcrumb: true,
                        submenu: []
                    }
                ]
            }
        ]
    },
    {
        key: "course",
        path: "/app/manage/courses",
        title: "Certification Programme",
        icon: "",
        breadcrumb: true,
        aclGroup: ["admin", "tele-caller"],
        submenu: [
            {
                key: "manage-courses",
                path: "/app/manage/courses",
                title: "Manage Courses",
                icon: SnippetsOutlined,
                breadcrumb: true,
                submenu: [],
                aclGroup: ["admin"]
            },
            {
                key: "manage-chapters",
                path: "/app/manage/chapter/modular",
                title: "Manage Modular Chapters",
                icon: SisternodeOutlined,
                breadcrumb: false,
                submenu: [],
                aclGroup: ["admin"]
            },
            {
                key: "manage-batches",
                path: "/app/manage/batches",
                title: "Manage Batches",
                icon: CalendarOutlined,
                breadcrumb: true,
                submenu: [],
                aclGroup: ["admin"]
            },
            {
                key: "manage-entrances",
                path: "/app/manage/entrances",
                title: "Manage Entrances",
                icon: EditOutlined,
                breadcrumb: true,
                submenu: [],
                aclGroup: ["admin"]
            },
            {
                key: "manage-student-request",
                path: "/app/manage/studentrequests",
                title: "Manage Financial Aid",
                icon: DollarCircleOutlined,
                breadcrumb: true,
                submenu: [],
                aclGroup: ["admin"]
            },
            {
                key: "manage-enrollments",
                path: "/app/manage/enrollments",
                title: "Manage Enrollments",
                icon: UserOutlined,
                breadcrumb: true,
                submenu: [],
                aclGroup: ["admin"]
            },
            {
                key: "manage-coupons",
                path: "/app/manage/coupons",
                title: "Manage Coupons",
                icon: PercentageOutlined,
                breadcrumb: true,
                submenu: [],
                aclGroup: ["admin", "tele-caller"]
            },
            {
                key: "manage-forum",
                path: "/app/manage/forum",
                title: "Manage Forum",
                icon: CommentOutlined,
                breadcrumb: true,
                submenu: [],
                aclGroup: ["admin"]
            },
            {
                key: "certification-exam",
                path: "/app/manage/certification/exam",
                title: "Certification Exam",
                icon: CommentOutlined,
                breadcrumb: true,
                submenu: [],
                aclGroup: ["admin"]
            }   
        ]
    },
    {
        key: "support",
        path: "/app/support",
        title: "Support",
        icon: "",
        breadcrumb: true,
        aclGroup: "admin",
        hidden: true,
        submenu: [
            {
                key: "manage-tickets",
                path: "/app/manage/tickets",
                title: "Tickets",
                icon: CustomerServiceOutlined,
                breadcrumb: true,
                submenu: []
            }
        ]
    },
    {
        key: "Sales knowledge",
        path: "/app/sales",
        title: "Sales Knowledge",
        aclGroup: "tele-caller",
        submenu: []
    }
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
