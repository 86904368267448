import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, NAV_TYPE_TOP } from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "QpiAI";
export const API_BASE_URL = env.API_ENDPOINT_URL;

export const THEME_CONFIG = {
  navCollapsed: true,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_TOP,
  topNavColor: "#3e82f7",
  headerNavColor: "#ffffff",
  mobileNav: false,
};
