import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import UtmFriendlyRedirect from "components/shared-components/UtmRedirect";

export const AppViews = ({match}) => {
  const token = JSON.parse(localStorage.getItem('token'));
  return (
    <Suspense fallback={<Loading cover="page"/>}>
      <Switch>
        {
          token && (<UtmFriendlyRedirect from={`${match.url}`} to="/app" />)
        }
        <Route exact path={`${match.url}/login`} component={lazy(() => import(`./authentication/login`))} />
        <Route exact path={`${match.url}/register`} component={lazy(() => import(`./authentication/register`))} />
        <Route exact path={`${match.url}/register/usd`} component={lazy(() => import(`./authentication/register/usd.js`))} />
        <Route path={`${match.url}/trial`} component={lazy(() => import(`./authentication/trial`))} />
        <Route path={`${match.url}/forgot-password`} component={lazy(() => import(`./authentication/forgot-password`))} />
        <UtmFriendlyRedirect from={`${match.url}`} to={`${match.url}/login`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews;

