import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactGA from 'react-ga4';
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { Button, ConfigProvider, Input, message } from 'antd';
import axios from 'axios';
import Utils from "utils";
import Modal from "antd/lib/modal/Modal";
import { USER_URL } from "constants/ApiConstant";
import toast, { Toaster } from 'react-hot-toast';
import UtmFriendlyRedirect from "components/shared-components/UtmRedirect";


const VerifyEmail = ({token}) => {
  const [prevToken, setPrevToken] = useState(null);
  const [user, setUser] = useState(null);
  const [showVerify, setShowVerify] = useState(false);
  const [verifyCode, setVerifyCode] = useState(null);
  const [loading, setLoading] = useState(false);

  const openVerify = (t) => {
    toast.dismiss(t.id);
    setShowVerify(true);
  }

  const closeVerify = (t) => {
    setShowVerify(false);
  }

	const onReload = async () => {
		try {
			const userRes = await axios.get(`${USER_URL}/me`);
			if (userRes.data.status === 'success') {
				const { user } = userRes.data;
        if (user.verifyTokenEmail) {
          toast((t) => (
            <span className="d-flex align-items-center">
              Please verify your email address
              <Button type="link" onClick={openVerify}>
                Click here
              </Button>
            </span>
          ));
        }
        setUser(user);
			} else {
				message.error({ content: userRes.data.error, duration: 3 });
			}
		} catch (err) {
			message.error({ content: err.message, duration: 3 });
		}
	}

  const onResendCode = async () => {
		setLoading(true);
		try {
			const res = await axios.put(`${USER_URL}/verify/resend`, {
				type: 'email'
			});
			if (res.data.status === 'success') {
				message.success({ content: 'Successfully resent code to your email address',  duration: 1.5 });
			} else {
				message.error({ content: res.data.error,  duration: 1.5 });
			}
		} catch (err) {
			message.error({ content: err.message,  duration: 1.5 });
		}
		setLoading(false);
  }

  const onVerifyEmail = async () => {
		setLoading(true);
		try {
			const res = await axios.put(`${USER_URL}/verify/email/${verifyCode}`, {
				email: user.email
			});
			if (res.data.status === 'success') {
				message.success({ content: 'Successfully verified your account',  duration: 1.5 });
        user.verifyTokenEmail = null;
        setShowVerify(false);
				setUser(user)
			} else {
				message.error({ content: res.data.error,  duration: 1.5 });
			}
		} catch (err) {
			message.error({ content: err.message,  duration: 1.5 });
		}
		setLoading(false);
  }

  useEffect(() => {
    if (JSON.stringify(token) !== JSON.stringify(prevToken) && user === null) {
      onReload();
    }
    setPrevToken(token);
  }, [token]);

  return (
    <>
      <Modal
        closable={true}
        centered={true}
        visible={showVerify}
        onCancel={closeVerify}
        title="Verify Account"
        footer={[
          <Button key="resend" type="secondary" loading={loading} onClick={onResendCode}>
            Resend Code
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={onVerifyEmail}>
            Verify Email
          </Button>,
        ]}
      >
        <h5>Please enter the verification code sent to your email address (If its not in your inbox, please check your spam folder)</h5>
        <Input
          placeholder="Verification code"
          value={verifyCode}
          maxLength={6}
          onChange={e => setVerifyCode(e.target.value)}
        />
      </Modal>
      <Toaster toastOptions={{ duration: Infinity }} />
    </>
  )
}

export const Views = (props) => {
  const { locale } = props;
  const currentAppLocale = AppLocale[locale];
  const token = JSON.parse(localStorage.getItem('token'));

  if (token != null) {
    axios.defaults.headers.common.Authorization = token.token;
  }

  let redirectTo = Utils.redirectTo() || '/app';
  redirectTo = redirectTo.replace(window.location.origin, '');

  if (token) {
    Utils.setRedirectTo('/app');
  }

  useEffect(() => {
    ReactGA.initialize('G-3KQGQ5H7JL');
  }, []);

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd}>
        <Switch>
          <Route exact path="/">
            {
              token && (<UtmFriendlyRedirect to={redirectTo} />)
            }
            {
              !token && (<UtmFriendlyRedirect to="/auth/login" />)
            }
          </Route>
          <Route path="/auth">
            <AuthLayout />
          </Route>
          <Route path="/app">
            <AppLayout />
          </Route>
        </Switch>
        <VerifyEmail token={token} />
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale } =  theme;
  const { token } = auth;
  return { locale, token }
};

export default withRouter(connect(mapStateToProps)(Views));