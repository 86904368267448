import React from 'react'
import { APP_NAME } from 'configs/AppConfig';

export default function Footer() {
	return (
		<footer className="footer noprint">
			<span>Copyright  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">{`${APP_NAME}`}</span> All rights reserved.</span>
			<div>
				<a className="text-gray" href="https://www.qpiai-explorer.tech/tc-app/" target="_blank">Term & Conditions</a>
				<span className="mx-2 text-muted"> | </span>
				<a className="text-gray" href="https://qpiai.tech/privacyandpolicy" target="_blank">Privacy & Policy</a>
			</div>
		</footer>
	)
}

