export const API_BASE_URL = "https://server.qpiai-explorer.tech";
export const USER_URL = `${API_BASE_URL}/user`;
export const LOGIN_REQUEST_URL = `${API_BASE_URL}/loginrequest`;
export const ESTIMATE_URL = `${API_BASE_URL}/estimate`;
export const ORG_URL = `${API_BASE_URL}/organisation`;
export const CATEGORY_URL = `${API_BASE_URL}/category`;
export const CONTENT_URL = `${API_BASE_URL}/content`;
export const BATCH_URL = `${API_BASE_URL}/batch`;
export const COURSE_URL = `${API_BASE_URL}/course`;
export const STUDENT_REQUEST_URL = `${API_BASE_URL}/studentrequest`;
export const ENROLLMENT_URL = `${API_BASE_URL}/enrollment`;
export const CHAPTER_URL = `${API_BASE_URL}/chapter`;
export const SUB_CHAPTER_URL = `${API_BASE_URL}/subchapter`;
export const LESSON_URL = `${API_BASE_URL}/lesson`;
export const POST_URL = `${API_BASE_URL}/post`;
export const REPLY_URL = `${API_BASE_URL}/reply`;
export const FORUM_CATEGORY_URL = `${API_BASE_URL}/forumcategory`;
export const ASSESSMENT_URL = `${API_BASE_URL}/assessment`;
export const LESSON_COMPLETE_URL = `${API_BASE_URL}/lessoncomplete`;
export const COUPON_URL = `${API_BASE_URL}/coupon`;
export const UPLOADS_URL = `${API_BASE_URL}/uploads`;
export const DASHBOARD_URL = `${API_BASE_URL}/report/dashboard`;
export const UTM_URL = `${API_BASE_URL}/report/utm`;
export const FINAL_EXAM_URL = `${API_BASE_URL}/finalexam`;

export const SUBSCRIPTION_URL = `${API_BASE_URL}/subscription`;
export const RAZORPAY_URL = `https://api.razorpay.com/v1`;
export const RAZORPAY_ID = "rzp_live_HObkH5eZTgfXKK";
export const RAZORPAY_PLANS = {
  "RAZORPAY_PERSONAL_MONTHLY": "plan_Fp1u7BbJPvVDq6",
  "RAZORPAY_PERSONAL_YEARLY": "plan_Fp1xU48qjd2PU3",
  "RAZORPAY_ORG_MONTHLY": "plan_Fp1ygoC6hKXS2g",
  "RAZORPAY_ORG_YEARLY": "plan_Fp1zMqgZbA0DDR"
};

export const PAYPAL_ID = "AU5cKRrPDQ22SLlMpASGRKyuwboWnozbVALcUifWXIwcp9qwMJotIfPWzxxth7tCkvLW3QuVviRnRLVD";
export const PAYPAL_PLANS = {
  "PP_PERSONAL_MONTHLY": "P-61Y73816GD464552RMA66X6I",
  "PP_PERSONAL_YEARLY": "P-69107161NP230904TMA66YTY",
  "PP_ORG_MONTHLY": "P-6EK271563Y201530BMA662JI",
  "PP_ORG_YEARLY": "P-7CF62077LS484461TMA663EI",
  "PP_PERSONAL_COURSE": "P-0GT047796V879963DMFXL5XQ"
};
