import React from 'react';
import { Redirect } from 'react-router';

const UtmFriendlyRedirect = props => {
  console.log(props);
  return (
    <Redirect
      to={{
        pathname: props.to,
        search: new URL(window.location.href).search
      }}
    />
  );
};

export default UtmFriendlyRedirect;