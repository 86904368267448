import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import axios from 'axios';
import Utils from 'utils';

function App() {
  useEffect(() => {
    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
        if (401 === error.response.status) {
            const currentUrl = String(window.location.href);
            Utils.setRedirectTo(currentUrl);
            localStorage.setItem('token', null);
            window.location.href = '/auth/login';
        } else {
            return Promise.reject(error);
        }
    });
  }, [])

  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/" component={Views}/>
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
